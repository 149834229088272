import React, { useEffect } from "react";
import { Root } from "./style";

import { Link } from "gatsby";
import platform from "platform";

import ContractLinks from "components/Common/ContractLinks";
import SiteData from "components/Common/SiteData";

import GooglePlay from "assets/Index/CallToAction/MobileApp/google-play.png";

import vars from "variables";

const GetPaid = () => {
  useEffect(() => {
    if (platform.os.family.includes("iOS")) {
      const head = document.head || document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      style.appendChild(
        document.createTextNode(`
          div.app-stores a.google {
            display: none;
          }
        `)
      );
      head.appendChild(style);
    }
  }, []);

  return (
    <Root>
      <SiteData path="index.getPaid" />
      <div>
        <strong>🚨 Important Notice: $HODL Token Security Breach 🚨</strong>
        <p>
          The $HODL token contract has been compromised. Please do not interact
          with the $HODL contract until further notice. We are actively working
          on a recovery plan and will share updates soon in our Official
          Telegram. Thank you for your patience and support!
        </p>
      </div>
      <div className="app-stores">
        <a
          className="google"
          href="https://play.google.com/store/apps/details?id=net.hodltoken.hodlapp&pcampaignid=web_share"
        >
          <img src={GooglePlay} />
        </a>
      </div>
    </Root>
  );
};

export default GetPaid;
